import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'no',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
		whitelist: ['en', 'no'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },
    resources: {
      no: {
        translation: {
					'recommendations': 'anbefalinger',
          			'By': 'Av',
					'View course': 'Se vandringen',
					'attractions': 'stoppesteder',
					'Show as list': 'Vis som liste',
					'Show in map': 'Vis som kart',
					'All courses': 'Alle vandringer',
					'All attractions': 'Alle stoppesteder',
					'Show': 'Vis',
					'Read more': 'Les mer',
					'Key information': 'Nøkkelinformasjon',
					'Attractions': 'Stoppesteder',
					'Map': 'Kart',
					'Level of difficulty': 'Vanskelighetsgrad',
					'Suitable for': 'Passer for',
					'Duration': 'Varighet',
					'Recommended starting point': 'Anbefalt startsted',
					'is a part of the courses': 'er en del av løypene',
					'Other stories from': 'Andre historier fra',
					'Show only': 'Vis kun',
					'Choose course': 'Velg vandring',
					'Loading': 'Laster inn',
					'Recommend': 'Anbefal',
					'Undo': 'Angre',
					'Zoomed in': 'Zoomet inn',
					'Zoomed out': 'Zoomet ut',
					'View course in Google Maps': 'Vis vandring i Google Maps',
					'Show my position': 'Vis min posisjon',
					'Unable to retrieve your location. Please allow your browser to use your position.': 'Kunne ikke hente din posisjon. Vennligst tillat din nettleser å bruke din posisjon.',
					'All': 'Alle',
					'Coursemap': 'Vandringskart',
					'Locating': 'Leter',
					'recommendation': 'anbefaling',
					'recommendations': 'anbefalinger',
					'Thanks!': 'Takk!',
					'Go to the course': 'Gå til vandringen',
					'Swipe': 'Sveip',
					'Printable version': 'Utskriftsvennlig versjon',
					'Remember to activate position tracking in your browser.': 'Husk å aktivere posisjon i nettleseren din.',
					'View sight': 'Se stoppested',
					'View location': 'Se lokasjon'
        }
      },
			en: {
        translation: {
					'recommendations': 'recommendations',
          'By': 'By',
					'View course': 'View tour',
					'attractions': 'sights',
					'Show as list': 'Show as list',
					'Show in map': 'Show in map',
					'All courses': 'All tours',
					'All attractions': 'All sights',
					'Show': 'Show',
					'Read more': 'Read more',
					'Key information': 'Key information',
					'Attractions': 'Sigths',
					'Map': 'Map',
					'Level of difficulty': 'Level of difficulty',
					'Suitable for': 'Suitable for',
					'Duration': 'Duration',
					'Recommended starting point': 'Recommended starting point',
					'is a part of the courses': 'is a part of the tours',
					'Other stories from': 'Other stories from',
					'Show only': 'Show only',
					'Choose course': 'Choose tour',
					'Loading': 'Loading',
					'Recommend': 'Recommend',
					'Undo': 'Undo',
					'Zoomed in': 'Zoomed in',
					'Zoomed out': 'Zoomed out',
					'View course in Google Maps': 'View tour in Google Maps',
					'Show my position': 'Show my position',
					'Unable to retrieve your location. Please allow your browser to use your position.': 'Unable to retrieve your location. Please allow your browser to use your position.',
					'All': 'All',
					'Coursemap': 'Tourmap',
					'Locating': 'Locating',
					'recommendation': 'recommendation',
					'recommendations': 'recommendations',
					'Thanks!': 'Thanks!',
					'Go to the course': 'Go to the tour',
					'Swipe': 'Swipe',
					'Printable version': 'Printable version',
					'Remember to activate position tracking in your browser.': 'Remember to activate position tracking in your browser.',
					'View sight': 'View sight',
					'View location': 'View location'
        }
      }
    }
  });

export default i18n;
