import { throttle } from 'lodash';
import { supportsPassiveEvents } from 'detect-it';
import { gsap } from "gsap/all";
import ScrollTrigger from "gsap/ScrollTrigger";
import InteractableModals from './interactableModals';
import Draggable from './draggable';
import Carousel from './carousel';
import MovingElements from './movingElements';

gsap.registerPlugin(ScrollTrigger);

export default class Front {
	constructor () {
		this.frontpageSectionIndicator = document.getElementById('frontpage-section-indicator-counter');
		this.sectionIndicatorOuterContainer = document.querySelector('.section-indicator-outer-container');
		this.sections = document.body.querySelectorAll('#top-section, #intro-section, #courses-section, #attractions-section, #about-section');

		this.init = this.init.bind(this);
		this.scrollTriggers = this.scrollTriggers.bind(this);
		this.scrollCallback = this.scrollCallback.bind(this);
	}

	init () {
		console.log('Init homepage');

		this.scrollTriggers();
		this.courses();

		const maps = new InteractableModals();
		maps.init();

		const carou = new Carousel();
		carou.init();

		this.listenScroll();

		const movingTop = new MovingElements({
			elements: document.body.querySelectorAll('.front-hero-counters, .frontpage-floating-images, .front-map .draggable'),
	    clipBox: {width: 400, height: 400},
			container: document.body.querySelector('#top-section'),
	    fullscreen: false
		});
		movingTop.init();

		const movingTop2 = new MovingElements({
			elements: document.body.querySelectorAll('.cloud svg'),
	    clipBox: {width: 500, height: 300},
			container: document.body.querySelector('#top-section'),
	    fullscreen: false
		});
		movingTop2.init();

		const movingIntroMap = new MovingElements({
			elements: document.body.querySelectorAll('#intro-section .illustration-map'),
	    clipBox: {width: 400, height: 400},
			container: document.body.querySelector('#intro-section'),
	    fullscreen: false
		});
		movingIntroMap.init();
		const movingCoursesMap = new MovingElements({
			elements: document.body.querySelectorAll('#courses-section .illustration-map'),
	    clipBox: {width: 400, height: 400},
			container: document.body.querySelector('#courses-section'),
	    fullscreen: false
		});
		movingCoursesMap.init();

		const movingAboutMap = new MovingElements({
			elements: document.body.querySelectorAll('#about-section .illustration-map .mediacontainer'),
	    clipBox: {width: 400, height: 400},
			container: document.body.querySelector('#about-section'),
	    fullscreen: false
		});
		movingAboutMap.init();
	}

	listenScroll () {
		const thro = throttle(this.scrollCallback, 150);
		window.addEventListener('scroll', thro, supportsPassiveEvents ? { passive: true, capture: false } : false);
	}

	scrollCallback (e) {
		const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
		this.sections.forEach((item, i) => {
			if (item.getBoundingClientRect().top < (screenHeight - 80) && item.getBoundingClientRect().top > 0 && item.dataset.theme !== this.sectionIndicatorOuterContainer.dataset.theme) {
				console.log('in frame, and change theme of sectionIndicator:', item.dataset.theme);
				this.sectionIndicatorOuterContainer.classList.remove('theme-brown', 'theme-dark', 'theme-blue', 'theme-beige', 'theme-beige-orange');
				this.sectionIndicatorOuterContainer.classList.add(item.dataset.theme);
				this.sectionIndicatorOuterContainer.setAttribute('data-theme', item.dataset.theme);
			}
		});
	}

	courses () {
		var changeCourseButtons = document.body.querySelectorAll('[data-attractions-listitem-btn]');
		var courseItems = document.body.querySelectorAll('[data-attractions-listitem]');
		var routeIllustration = document.body.querySelector('.route-illustration');

		console.log({changeCourseButtons});
		if (changeCourseButtons && changeCourseButtons.length) {
			changeCourseButtons.forEach((item, i) => {
				item.addEventListener('click', (e) => {
					changeCourseButtons.forEach((b, i) => {
						if (b.hasClass('active')) {
							b.classList.remove('active');
							b.setAttribute('aria-selected', 'false');
						}
					});
					item.classList.add('active');
					item.setAttribute('aria-selected', 'true');

					const id = item.dataset.attractionsListitemBtn;
					console.log({id});
					const course = document.body.querySelector('[data-attractions-listitem="' + id + '"]');
					console.log({course});
					courseItems.forEach((c, i) => {
						if (c.hasClass('active')) {
							c.classList.remove('active');
						}
						if (c.dataset.attractionsListitem === id) {
							c.classList.add('active');
							routeIllustration.src = c.dataset.attractionsListitemImg;
							routeIllustration.classList.add('hidden');
							setTimeout(() => {
								routeIllustration.classList.remove('hidden');
							}, 20)
						}
					});
				})
			});
		}
	}

	interactableMapModals () {
		const mq = window.matchMedia("(max-width: 767px)");
		if (!mq.matches) {
			console.warn('Screen to big. Skipping interactable maps.');
			return;
		}
		const triggers = document.body.querySelectorAll('.view-detailed-map, .view-interactable-course-map');
		if (triggers && triggers.length) {
			triggers.forEach((item, i) => {
				item.addEventListener('click', (e) => {
					document.body.classList.toggle('noscroll');
					if (e.target.classList.contains('view-detailed-map')) {
						console.log('Open detailed map');
					} else if (e.target.classList.contains('view-interactable-course-map')) {
						console.log('Open course map');
					} else {
						console.log('Dont open any???');
					}
				})
			});
		}
	}

	scrollTriggers () {
		var top = document.body.querySelector('#top-element');
		var topMap = top.querySelector('.front-map');
		var intro = document.body.querySelector('#intro-section');
		var introMap = intro.querySelector('.intro-map-container');
		var counters = top.querySelectorAll('.front-hero-counters');
		var floaters = top.querySelectorAll('.frontpage-floating-images');

		var courses = document.body.querySelector('#courses-section');
		var coursesMap = courses.querySelector('.course-illustration-container');
		var attractions = document.body.querySelector('#attractions-section');
		var attractionsMap = attractions.querySelector('.illustration-map');
		var about = document.body.querySelector('#about-section');
		var aboutMap = about.querySelector('.illustration-map');
		var topElement = document.body.querySelector('#top-element');

		var self = this;

		/*
		* Change themes on scroll
		*/
		ScrollTrigger.create({
			trigger: top,
			onLeave: function () {
				console.log('onLeave top');
				self.setTheme('theme-beige-orange');
			},
			onLeaveBack: function () {
				console.log('onLeaveBack top');
			}
		});
		ScrollTrigger.create({
			trigger: intro,
			onLeave: function () {
				self.setTheme('theme-beige');
			},
			onLeaveBack: function () {
				self.setTheme('theme-blue');
			},
		});
		ScrollTrigger.create({
			trigger: courses,
			onLeave: function () {
				self.setTheme('theme-dark');
			},
			onLeaveBack: function () {
				self.setTheme('theme-beige-orange');
			},
		});
		ScrollTrigger.create({
			trigger: attractions,
			onLeave: function () {
				self.setTheme('theme-brown');
			},
			onLeaveBack: function () {
				self.setTheme('theme-beige');
			},
		});
		ScrollTrigger.create({
			trigger: about,
			onLeaveBack: function () {
				self.setTheme('theme-dark');
			},
		});

		ScrollTrigger.matchMedia({
			"(max-width: 767px)": function () {
				gsap.fromTo(introMap, {scale: 1.4, rotate: 0, x: 20}, {
					rotate: 4,
					scale: 1.4,
					scrollTrigger: {
						trigger: intro,
						scrub: 2
					}
				});
				gsap.fromTo(topMap, {y: 0, x: -5, rotate: 0, scale: 1.1}, {
					y: -50, rotate: 2, scale: 1, x: 10,
				  scrollTrigger: {
				    trigger: intro,
				    scrub: true,
				  },
				});
			},
			"(min-width: 768px)": function () {
				gsap.fromTo('#top-element .intro-container, #top-element .overline-container, #top-element .title-container', {scale: 1, opacity: 1}, {scale: 0.7, opacity: 0,
				  scrollTrigger: {
				    trigger: intro,
				    start: "top 50%",
				    // end: "bottom top",
						stagger: 2,
				    scrub: 0.5,
				  },
				});

				gsap.fromTo('#top-element .intro-container, #top-element .overline-container, #top-element .title-container', {y: 0}, {y: -250,
				  scrollTrigger: {
				    trigger: intro,
				    // start: "top top",
				    end: "50% top",
						stagger: 2,
				    scrub: 0.5,
				  },
				});
				gsap.fromTo(topMap, {y: 0, rotate: -2, scale: 1.1, opacity: 1}, {y: -200, rotate: 2, scale: 0.8,
				  scrollTrigger: {
				    trigger: intro,
				    scrub: true,
				  },
				});

				// counters.forEach((item, i) => {
				// 	gsap.to(item, {delay: i * 10, y: -(200 * (i+1)), scale: 0.8,
				// 	  scrollTrigger: {
				// 	    trigger: intro,
				// 	    scrub: 1,
				// 	  },
				// 	});
				// });
				// floaters.forEach((item, i) => {
				// 	gsap.to(item, {delay: i * 10, y: -(100 * (i+1)), scale: 0.9,
				// 	  scrollTrigger: {
				// 	    trigger: intro,
				// 	    scrub: 1,
				// 	  },
				// 	});
				// });

				gsap.to(introMap, {
					rotate: 4,
					scale: 1.02,
					scrollTrigger: {
						trigger: introMap,
						scrub: 1.5
					}
				});
				gsap.to(coursesMap, {
					rotate: -4,
					scale: 1.02,
					scrollTrigger: {
						trigger: coursesMap,
						scrub: 1.5
					}
				});
				gsap.to(aboutMap, {
					rotate: 4,
					y:30,
					scale: 1.05,
					scrollTrigger: {
						trigger: about,
						scrub: 1.5
					}
				});
			}
		});
	}

	setTheme (theme) {
		document.body.classList.remove('theme-brown', 'theme-dark', 'theme-blue', 'theme-beige', 'theme-beige-orange');
		document.body.classList.add(theme);

		let section = 1;
		switch (theme) {
			case 'theme-blue':
				section = 1;
				break;
			case 'theme-beige-orange':
				section = 2;
				break;
			case 'theme-beige':
				section = 3;
				break;
			case 'theme-dark':
				section = 4;
				break;
			case 'theme-brown':
				section = 5;
				break;
			default:
				// do nothing
		}
		const line = document.body.querySelector('.section-indicator-line.active');
		if (line) {
			line.classList.remove('active');
		}
		const active = document.body.querySelector('#section-' + section + '.section-indicator-line');
		active.classList.add('active');

		this.frontpageSectionIndicator.innerHTML = '0'+section;
	}
}
