import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import { gsap } from "gsap/all";

Swiper.use([Navigation, Pagination, Autoplay]);

export default class Carousel {
  constructor(config = {}) {
    this.swipers = config.swipers
      ? config.swipers
      : document.querySelectorAll(".swiper-container:not(.react-handled)"); // Must be an array of Dom Elements, or nothing!
    this.allSwipers = [];
    this.config = config;

    this.init = this.init.bind(this);
    this.updateSlides = this.updateSlides.bind(this);
  }

  init() {
    let next = null;
    let prev = null;
    let slp = 1;
    let autoplay = false;
    let duration = 4000; // duration of each slide in autoplay-swipers.
    let pagination = null;
    let nested = false;

    console.log("INITTT:", this.config);
    this.swipers.forEach((swiper, i) => {
      // if only one slide, don't init swiper.
      if (swiper.querySelectorAll(".swiper-slide").length < 2) {
        console.warn("Swiper has less than 2 slides. Skipping swiper init.");
        return;
      }

      /* Multiple swipers on same page needs the html-element for navigation and pagination (in new Swiper(...)) to not interfere with each other */

      next = swiper.querySelector(".swiper-next")
        ? swiper.querySelector(".swiper-next")
        : null;
      prev = swiper.querySelector(".swiper-prev")
        ? swiper.querySelector(".swiper-prev")
        : null;
      pagination = swiper.querySelector(".swiper-pagination")
        ? swiper.querySelector(".swiper-pagination")
        : null;
      // pagination = swiper.querySelector('.swiper-pagination') || null;
      slp = 1;
      autoplay = false;
      nested = false;

      if (swiper.classList.contains("parent-nested-swiper")) {
        const mq = window.matchMedia("(max-width: 767px)");
        if (!mq.matches) {
          console.warn(
            "Current swiper is parent of a nested swiper, and the screen is bigger than mediaquery. Skip swiping. Swiping only necessary on mobile."
          );
          return;
        }
        console.log("parent-nested-swiper");
        pagination = null;
      }

      if (swiper.classList.contains("child-nested-swiper")) {
        nested = true;
      }

      if (swiper.classList.contains("swiper-auto-slides")) {
        slp = "auto";
      } else if (swiper.classList.contains("swiper-autoplay")) {
        autoplay = {
          disableOnInteraction: false,
          delay: duration,
        };
      }
      console.log({ nested });
      const swip = new Swiper(swiper, {
        slidesPerView: slp,
        navigation:
          next && prev
            ? {
                nextEl: next,
                prevEl: prev,
              }
            : {},
        pagination: {
          el: pagination,
          dynamicBullets: true,
        },
        autoplay: autoplay,
        loop: !!autoplay,
        nested: nested,
        direction: "horizontal",
        paginationClickable: true,
        resizeObserver: true,
        observer: true,
      });

      setTimeout(() => {
        const firstSlide = swiper.querySelector(".swiper-slide");

        swiper.style.width = firstSlide.style.width;
      }, 500);

      swip.on("slideChangeTransitionEnd", function (e) {
        if (swiper.dataset.changeNameWhenSliding) {
          const activeSlide = swiper.querySelector(".swiper-slide-active");
          const currentNameContainer = swiper.querySelector(
            "#featured-attractions-swiper-name"
          );
          const currentSlideIndicator = swiper.querySelector(
            "#featured-attractions-swiper-counter"
          );
          currentNameContainer.innerHTML = activeSlide.dataset.attractionName;
          currentSlideIndicator.innerHTML = e.activeIndex + 1;
        }
        if (swiper.dataset.removeDragIndicator) {
          const indicator = document.body.querySelector(
            "#" + swiper.dataset.removeDragIndicator
          );
          console.log({ indicator });
          if (indicator) {
            gsap.to(indicator, {
              opacity: 0,
              duration: 0.3,
            });
          }
        }
      });

      this.allSwipers.push(swip);
    });

    const self = this;
    window.addEventListener("resize", function (evt) {
      self.updateSlides();
      console.log("resized");
    });
  }

  updateSlides() {
    this.allSwipers.forEach((swiper, i) => {
      swiper.update();
    });
  }
}
