import interact from 'interactjs';

export default class Draggables {
	constructor (data = null) {
		this.draggables = null;
		this.selector = data && data.selector ? data.selector : '.draggable'; // Must be an Dom Element, or a query-string!
		this.axis = data && data.axis ? data.axis : 'x';
		this.interacting = []; // containing interactjs-object for unsetting.
		this.scale = 1;

		this.dragMoveListener = this.dragMoveListener.bind(this);
		this.init = this.init.bind(this);
		this.destroy = this.destroy.bind(this);
		this.setScale = this.setScale.bind(this);
	}

	init (afterDragCallback) {
		const mq = window.matchMedia("(max-width: 767px)");
		if (!mq.matches) {
			console.warn('Draggable not matching mediaquery. Skipping.');
			return;
		}

		this.draggables = this.selector instanceof Element ? [this.selector] : document.body.querySelectorAll(this.selector);

		if (this.draggables && this.draggables.length) {
			this.draggables.forEach((draggableElem, i) => {
				let dragged = false;
				const interacted = interact(draggableElem)
				  .draggable({
				    // enable inertial throwing
				    inertia: true,
				    // keep the element within the area of it's parent
				    modifiers: [
				      interact.modifiers.restrictRect({
				        restriction: 'parent',
				        endOnly: true
				      })
				    ],
				    // enable autoScroll
				    autoScroll: true,
						lockAxis: this.axis,
				    listeners: {
				      // call this function on every dragmove event
				      move: this.dragMoveListener,
							start: () => {
								console.log('Dragged:', dragged);
								if (!dragged && afterDragCallback) {
									console.log('Callback');
									afterDragCallback();
									dragged = true;
								}
							}
				    }
				  });
				this.interacting.push(interacted);
			});
		}
	}

	setScale (scale) {
		// if multiple draggables exists, this will scale ALL the draggables in this.draggables.
		// Avoid by initing new Draggable for each draggable element.
		console.log('setScale:', scale);
		this.scale = scale;
		this.draggables.forEach((target, i) => {
			var x = parseFloat(target.getAttribute('data-x')) || 0;
			var y = parseFloat(target.getAttribute('data-y')) || 0;

			target.style.transition = 'transform 0.2s'; // transitioning causes jittering when dragging, so only apply and remove it before scaling to scale smoothly.
			target.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0) rotate(' + x * -0.01 + 'deg) scale3d(' + scale + ', ' + scale + ', ' + scale + ')';
			setTimeout(() => {
				target.style.transition = ''; // transitioning causes jittering when dragging, so only apply and remove it before scaling to scale smoothly.
			}, 200);

		});
	}

	dragMoveListener (event) {
		var target = event.target;
		// get the dragged position in the data-x/data-y attributes
		var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
		var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

		target.style.transform = 'translate3d(' + x + 'px, ' + y + 'px, 0) rotate(' + x * -0.01 + 'deg) scale3d(' + this.scale + ', ' + this.scale + ', ' + this.scale + ')';

		// update the posiion attributes
		target.setAttribute('data-x', x);
		target.setAttribute('data-y', y);
	}

	destroy () {
		this.interacting.forEach((item, i) => {
			item.unset();
		});
	}
}
