let firstFocusableElement = null;
let lastFocusableElement = null;

const onKeydown = (e) => {
  if (e.key !== 'Tab' && e.keyCode !== 9) {
		// dont prevent default if is not tab- or enter-key
    return;
  }

  if (e.shiftKey) { // if shift key pressed for shift + tab combination
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus(); // add focus for the last focusable element
      e.preventDefault();
    }
  } else { // if tab key is pressed
    if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
      firstFocusableElement.focus(); // add focus for the first focusable element
      e.preventDefault();
    }
  }
}


export const trapModalFocus = (modal, focusableElements = null, preventScroll = false) => {
	focusableElements = focusableElements ?? 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

	const focusableContent = modal.querySelectorAll(focusableElements);

	if (focusableContent && focusableContent.length) {
		firstFocusableElement = focusableContent[0]; // get first element to be focused inside modal
		lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal

		firstFocusableElement.focus({
			preventScroll: preventScroll
		});
	}

	const listener = document.addEventListener('keydown', onKeydown);

	return {listener: document, callback: onKeydown};
}

export const releaseModalFocus = (listener, initiatingElement = null, preventScroll = false) => {
	console.log('Remove trapFocus-listener.');
	listener.listener.removeEventListener('keydown', listener.callback);
	if (initiatingElement) {
		initiatingElement.focus({
			preventScroll: preventScroll
		});
	}
}
