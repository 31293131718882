import Front from '../components/front';

if (document.readyState === 'loading') {
	document.addEventListener('DOMContentLoaded', initialize);
} else {
	initialize();
}

function initialize () {
	const fr = new Front();
	fr.init();
}
