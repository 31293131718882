import { gsap } from "gsap/all";

export default class MovingElements {
  constructor (config = {}) {

    this.debounce = true;

    this.elements = config.elements || [];
    this.clipBoxWidth = config.clipBox ? config.clipBox.width : window.innerWidth / 2;
    this.clipBoxHeight = config.clipBox ? config.clipBox.height : window.innerWidth / 2;
    this.container = config.container || document.body;
    this.fullscreen = typeof config.fullscreen === undefined ? true : config.fullscreen;
    this.matchMedia = config.matchMedia || '(pointer: fine) and (min-width: 1024px)';

    this.init = this.init.bind(this);
    this.moveHandler = this.moveHandler.bind(this);
    this.animationHandler = this.animationHandler.bind(this);
    this.isInViewport = this.isInViewport.bind(this);
    this.destroy = this.destroy.bind(this);
  }

  init () {
    window.addEventListener('mousemove', this.moveHandler);
  }

  moveHandler (e) {
    if(this.debounce && (this.fullscreen || window.matchMedia(this.matchMedia).matches)) {
      this.debounce = false
      window.setTimeout(() => {
        if(this.isInViewport()){
          this.animationHandler(e)
        }

        this.debounce = true
      }, 50);
    }
  }

  animationHandler (e) {
    const multiplierY = (e.clientY / window.innerHeight - 0.5) * 2
    const multiplierX = (e.clientX / window.innerWidth - 0.5) * 2

    let x = multiplierX * 0.2 * this.clipBoxWidth;
    let y = multiplierY * 0.2 * this.clipBoxHeight;

    this.elements.forEach(gp => {
      if(!gp.rand) gp.rand = { x: this.rand(), y: this.rand()}
      gsap.to(gp, {
        x: x * gp.rand.x,
        y: y * gp.rand.y,
        transformOrigin: '50% 50%',
        overwrite: true,
        ease: 'elastic.out(0.5, 0.2)',
        duration: 2.56
      })
    })
  }

  rand () {
    var random = (Math.random()*0.4) - 0.2;
    return random;
  }

  isInViewport () {
    const rect = this.container.getBoundingClientRect()

    return (
      rect.top <= window.innerHeight &&
      rect.bottom >= 0
    )
  }

  destroy () {
    window.removeEventListener('mousemove', this.moveHandler)
  }
}
