import { trapModalFocus, releaseModalFocus } from '../lib/trapModalFocus';
import { gsap } from 'gsap';
import Draggable from './draggable';
import svg from 'svg';
import pointer from '../../img/drag.svg';
import '../i18n';
import i18next from 'i18next';
import { decode } from 'html-entities';
import SVGInject from '@iconfu/svg-inject';

export default class InteractableModals {
	constructor (config = {}) {
		this.modal = null;
		this.trapModalFocusListener = null;
		this.logo = document.querySelector('.logo-container');
		this.burger = document.querySelector('.burger');
		this.initiatingButton = null;
		this.dragging = null;
		this.triggers = config.triggers ? config.triggers : null;

		this.init = this.init.bind(this);
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.interactableMapModals = this.interactableMapModals.bind(this);
	}

	init () {
		console.log('Init InteractableModals');

		const mq = window.matchMedia("(max-width: 767px)");
		if (!mq.matches) {
			console.warn('Screen to big. Skipping interactable maps.');
			return;
		}

		this.interactableMapModals();
	}

	interactableMapModals () {
		const triggers = this.triggers ? this.triggers : document.body.querySelectorAll('.interactable-map');
		console.log('hello', triggers);
		if (triggers && triggers.length) {
			triggers.forEach((item, i) => {
				item.addEventListener('click', (e) => {
					console.log('Click!');
					console.log({e});
					this.open(e.target.closest('.interactable-map'));
				})
			});
		}
	}

	open (elem) {
		console.log({elem});
		this.modal = document.createElement('div');
		this.modal.className = 'interactable-modal';

		let interactableContainer = document.createElement('div');
		interactableContainer.className = 'interactable-content draggable';

		let layersData = elem.getAttribute('data-layers');
		console.log(layersData);
		layersData = layersData ? JSON.parse(decode(layersData)) : null;
		console.log({layersData});
		if (layersData && layersData.length) {
			let i = 0;
			for (var l of layersData) {
				let layer = document.createElement('img');
				layer.src = l;
				layer.className = i === 0 ? 'w-full h-full' : 'absolute top-0 left-0 right-0 bottom-0';
				interactableContainer.appendChild(layer);
				i++;
			}
		}

		this.modal.appendChild(interactableContainer);

		let indicator = document.createElement("div");
		indicator.className = "dragging-indicator";
		const img = document.createElement('img');
		img.src = pointer;
		img.className = "w-6 h-6 mr-2";
		img.onload = () => {
			SVGInject(img); // Make it an svg element.
		}
		indicator.innerHTML = i18next.t('Swipe');
		indicator.prepend(img);
		this.modal.appendChild(indicator);

		let btnContainer = document.createElement('div');
		btnContainer.className = "controller-container";
		let leftBtnContainer = document.createElement('div');
		leftBtnContainer.className = "controller-container-left";
		let closeBtn = document.createElement("button");
		closeBtn.className = 'close-btn';
		closeBtn.appendChild(this.closeIcon());
		let zoomBtn = document.createElement("button");
		zoomBtn.className = 'zoom';
		zoomBtn.innerHTML = i18next.t('Zoomed in');
		let zoomOutBtn = document.createElement("button");
		zoomOutBtn.className = 'zoom active';
		zoomOutBtn.innerHTML = i18next.t('Zoomed out');
		leftBtnContainer.appendChild(zoomOutBtn);
		leftBtnContainer.appendChild(zoomBtn);

		btnContainer.appendChild(leftBtnContainer);
		btnContainer.appendChild(closeBtn);
		this.modal.appendChild(btnContainer);

		zoomOutBtn.className = 'zoom active';

		closeBtn.onclick = () => {
			console.log('Close!');
			this.close();
		};
		zoomBtn.onclick = () => {
			console.log('Zoom in!');
			this.dragging.setScale(2);
			zoomBtn.className = 'zoom active';
			zoomOutBtn.className = 'zoom';
		};
		zoomOutBtn.onclick = () => {
			console.log('Zoom out!');
			this.dragging.setScale(1);
			zoomBtn.className = 'zoom';
			zoomOutBtn.className = 'zoom active';
		};

		this.logo.classList.add('hidden');
		this.burger.classList.add('hidden');
		document.body.classList.add('noscroll');

		document.body.appendChild(this.modal);

		// opening modal. Trap the focus inside container, after modal added to document.
		this.trapModalFocusListener = trapModalFocus(this.modal);
		this.initiatingButton = elem;

		this.dragging = new Draggable({
			selector: interactableContainer,
			axis: 'xy'
		});
		this.dragging.init(function () {
			gsap.to(indicator, {opacity: 0, onComplete: () => {
				console.log('Animated. Remove');
				indicator.remove();
			}});
		});

		setTimeout(() => {
			this.modal.classList.add('open');
		}, 100);
	}

	close () {
		console.log('Closing');
		this.modal.classList.remove('open');
		document.body.classList.remove('noscroll');

		if (this.trapModalFocusListener) {
			// removes the event-listener and allows tabbing outside specified modal. And set focus to initiating element
			releaseModalFocus(this.trapModalFocusListener, this.initiatingButton);
			this.trapModalFocusListener = null;
		}

		if (this.dragging) {
			this.dragging.destroy();
		}

		setTimeout(() => {
			this.logo.classList.remove('hidden');
			this.burger.classList.remove('hidden');
			this.modal.remove();
		}, 300);
	}

	closeIcon () {
		const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
		const line1 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
		const line2 = document.createElementNS('http://www.w3.org/2000/svg', 'line');
		svg.setAttribute('width', "24");
		svg.setAttribute('height', "17");
		svg.setAttribute('viewBox', "0 0 24 20");
		line1.setAttribute('id', 'top');
		line1.setAttribute('x1', '0');
		line1.setAttribute('x2', '24');
		line1.setAttribute('y1', '1');
		line1.setAttribute('y2', '1');
		line2.setAttribute('id', 'bottom');
		line2.setAttribute('x1', '0');
		line2.setAttribute('x2', '24');
		line2.setAttribute('y1', '16');
		line2.setAttribute('y2', '16');
		line1.setAttribute('stroke-width', '2');
		line2.setAttribute('stroke-width', '2');
		line1.setAttribute('stroke', '#F8F0DD');
		line2.setAttribute('stroke', '#F8F0DD');

		svg.appendChild(line1);
		svg.appendChild(line2);
		return svg;
	}
}
